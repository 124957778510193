import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useOTP } from "./OTPContext";
import GreenTick from "./yes.png";

const SuccessPage = () => {
  const { changeId } = useOTP();
  const location = useLocation();
  const formData = location.state; // Retrieve formData passed via navigate

  useEffect(() => {
    const checkDNSPropagation = async () => {
      try {
        const response = await axios.post("/api/handleDNSPropagation", {
          changeId: changeId, // Use changeId from formData
        });

        if (response.status === 200) {
          // Redirect to the new subdomain
          window.location.href = `https://${formData.subdomain}.nroo.one`;
        } else {
          console.error("DNS propagation failed:", response.data.message);
          // Handle propagation failure here if needed
        }
      } catch (error) {
        console.error("Error checking DNS propagation", error);
        // Handle API error here if needed
      }
    };

    checkDNSPropagation(); // Call the API immediately when the page loads
  }, [changeId, formData.subdomain]);

  return (
    <div className="container-fluid">
      <div className="container text-center">
        <div className="tick_div">
          <img className="tick_png" src={GreenTick} alt="green_tick" />
        </div>
        <h4>Your account has been activated</h4>
        <p>Redirecting to your business URL...</p>
        <div
          className="spinner-border text-primary"
          role="status"
          style={{ margin: "10px", width: "2rem", height: "2rem" }}
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
