import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useOTP } from "./OTPContext";
import axios from "axios";

const Register = () => {
  const { setOtp, setChangeId } = useOTP();
  const [formErrors, setFormErrors] = useState({});
  const location = useLocation();
  const [formData, setFormData] = useState({
    companyName: "",
    company_owner: "",
    email: "",
    subdomain: "",
    phoneNumber: "",
    password: "",
    businessType: "",
  });

  useEffect(() => {
    if (location.state) {
      const { planName, planPrice, planType } = location.state;
      const company_id = Math.floor(Math.random() * 900000) + 100000;
      setFormData((prevData) => ({
        ...prevData,
        planName: planName,
        planPrice: planPrice,
        planType: planType,
        company_id: company_id,
      }));
    }
  }, [location.state]);

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Handle input changes for non-phone inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setFormData({
        ...formData,
        [name]: value.toLowerCase(),
      });
    } else if (name === "subdomain") {
      setFormData({
        ...formData,
        [name]: value.toLowerCase(),
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // Handle phone number change
  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phoneNumber: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const errors = {};

    // Validate each field
    if (!formData.companyName.trim()) {
      errors.companyName = "Company name is required.";
    }
    if (!formData.company_owner.trim()) {
      errors.company_owner = "Owner name is required.";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required.";
    }
    if (!formData.subdomain.trim()) {
      errors.subdomain = "Subdomain is required.";
    } else {
      const subdomainRegex = /^[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
      if (!subdomainRegex.test(formData.subdomain)) {
        errors.subdomain =
          "Subdomain can only contain lowercase letters, numbers, and hyphens. It cannot start or end with a hyphen.";
      }
      if (formData.subdomain.length < 3 || formData.subdomain.length > 63) {
        errors.subdomain =
          "Subdomain must be between 3 and 63 characters long.";
      }
    }
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = "Phone number is required.";
    }
    if (!formData.password.trim()) {
      errors.password = "Password is required.";
    }
    if (!formData.businessType.trim()) {
      errors.businessType = "Business type is required.";
    }

    // If errors exist, update state and stop submission
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setIsLoading(false);
      return;
    }

    try {
      // Check for server-side errors
      await axios.post("/api/checkEmail", {
        email: formData.email,
      });

      await axios.post("/api/checkSubdomain", {
        subdomain: formData.subdomain,
      });

      await axios.post("/api/checkPhone", {
        phoneNumber: formData.phoneNumber,
      });

      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }

      const registerResponse = await axios.post("/api/createCompany", {
        company_id: formData.company_id,
        company_name: formData.companyName,
        company_mobile: formData.phoneNumber,
        company_email: formData.email,
        company_owner: formData.company_owner,
        subdomain: formData.subdomain,
        company_password: formData.password,
        business_type: formData.businessType,
        title: formData.planName,
        plan: formData.planType,
        price: formData.planPrice,
      });
      console.log("registerResponse data", registerResponse);
      const registerdata = registerResponse.data.data;
      setChangeId(registerdata.changeId);

      // Generate OTP if no errors
      const otpResponse = await axios.post("/api/generateOtp", formData);
      const data = otpResponse.data;

      if (data.otp) {
        setOtp(data.otp);
        navigate("/otp-validation", { state: formData });
      }
    } catch (error) {
      console.error("Error during registration:", error.response.data.message);
      if (error.response.data.message === "Subdomain already exists") {
        errors.subdomain = "Subdomain is already taken.";
      }
      if (error.response.data.message === "Email already exists") {
        errors.email = "Email already exists. Please use a different email.";
      }
      if (error.response.data.message === "Phone Number already exists") {
        errors.phoneNumber = "Phone number already exists.";
      }
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const business_types = [
    { value: "Physical Products", label: "Vente de produit physique" },
    { value: "Service Sales", label: "Vente de Services" },
    // { value: "Pharmacy", label: "Pharmacy" },
    // { value: "Restaurant", label: "Restaurant" },
    // { value: "Manufacturing", label: "Manufacturing" },
    // { value: "Digital Product Sales", label: "Digital Product Sales" },
    // { value: "Consulting", label: "Consulting" },
    // { value: "Real Estate", label: "Real Estate" },
  ];

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="mt-3 mb-3">
          <p className="h2 text-center">Register</p>
        </div>

        <div className="registration-container">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Company Name:</label>
              <input
                type="text"
                name="companyName"
                className="form-control"
                placeholder="Enter your company name"
                value={formData.companyName}
                onChange={handleChange}
              />
              {formErrors.companyName && (
                <p className="text-danger">{formErrors.companyName}</p>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Owner Name:</label>
              <input
                type="text"
                name="company_owner"
                placeholder="Enter owner name"
                className="form-control"
                value={formData.company_owner}
                onChange={handleChange}
              />
              {formErrors.company_owner && (
                <p className="text-danger">{formErrors.company_owner}</p>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Email:</label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                className="form-control"
                value={formData.email}
                onChange={handleChange}
              />
              {formErrors.email && (
                <p className="text-danger">{formErrors.email}</p>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Subdomain:</label>
              <input
                type="text"
                name="subdomain"
                className="form-control"
                placeholder="yoursubdomain so that url will be yoursubdomain.nroo.one"
                value={formData.subdomain}
                onChange={handleChange}
              />
              {formErrors.subdomain && (
                <p className="text-danger">{formErrors.subdomain}</p>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Business Type:</label>
              <select
                name="businessType"
                className="form-select"
                value={formData.businessType}
                onChange={handleChange}
              >
                <option value="">Select a business type</option>
                {business_types.map((data, index) => {
                  return (
                    <option key={index} value={data.value}>
                      {data.label}
                    </option>
                  );
                })}
              </select>
              {formErrors.businessType && (
                <p className="text-danger">{formErrors.businessType}</p>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Phone Number:</label>
              <PhoneInput
                country={"bj"}
                onlyCountries={["bj"]}
                disableDropdown={true}
                disableCountryCode={true}
                value={formData.phoneNumber}
                onChange={handlePhoneChange}
                inputProps={{
                  name: "phone",
                  className: "phone-field form-control",
                }}
              />
              {formErrors.phoneNumber && (
                <p className="text-danger">{formErrors.phoneNumber}</p>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Password:</label>
              <div className="input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  className="form-control"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                />
                <button
                  type="button"
                  className="input-group-text password-toggle"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              {formErrors.password && (
                <p className="text-danger">{formErrors.password}</p>
              )}
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="btn-primary w-100"
                disabled={isLoading}
              >
                {isLoading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Register"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
