import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import translations from "./lang/translation.js";
import { FaCheckCircle } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";

function Main() {
  const [language, setLanguage] = useState("fr");
  const [activeTab, setActiveTab] = useState("monthly");

  const navigate = useNavigate();

  // eslint-disable-next-line
  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  const t = translations[language];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const plans = {
    monthly: [
      {
        name: t.starter,
        price: 9700,
        features: t.checkItems[0],
        type: "mois",
      },
      {
        name: t.standard,
        price: 27900,
        features: t.checkItems[1],
        isPopular: true,
        type: "mois",
      },
      {
        name: t.premium,
        price: 51700,
        features: t.checkItems[2],
        type: "mois",
      },
    ],
    yearly: [
      {
        name: t.starter,
        price: 97000,
        features: t.checkItems[0],
        type: "an",
      },
      {
        name: t.standard,
        price: 279000,
        features: t.checkItems[1],
        isPopular: true,
        type: "an",
      },
      {
        name: t.premium,
        price: 517000,
        features: t.checkItems[2],
        type: "an",
      },
    ],
  };

  // Function to navigate to the registration page with plan data
  const handleRegisterClick = (plan) => {
    const dataToPass = {
      // planName: plan.name,
      // planPrice: plan.price,
      planName: "FREE",
      planPrice: 0,
      planType: activeTab, // Pass "monthly" or "yearly"
    };
    navigate("/registration", { state: dataToPass });
  };

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="mt-3 mb-3">
          <p className="h2 text-center">{t.title}</p>
        </div>

        {/* Tabs */}
        <div className="row outer-pills">
          <ul className="nav nav-pills">
            <div className="border-pill">
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    activeTab === "monthly" ? "active" : ""
                  }`}
                  to="#"
                  onClick={() => handleTabClick("monthly")}
                >
                  {t.monthlyTab}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    activeTab === "yearly" ? "active" : ""
                  }`}
                  to="#"
                  onClick={() => handleTabClick("yearly")}
                >
                  {t.yearlyTab}
                  {activeTab === "yearly" && (
                    <span className="text-theme"> Save ~20%</span>
                  )}
                </Link>
              </li>
            </div>
          </ul>
        </div>

        {/* Plans */}
        <div className="plans mt-4 mb-4">
          {plans[activeTab].map((plan, index) => (
            <div
              className={`plan-card ${plan.isPopular ? "popular" : ""}`}
              key={index}
            >
              {plan.isPopular && <div className="badge">{t.popularBadge}</div>}
              <h3 className="plan-name" translate="no">
                {plan.name}
              </h3>
              <p className="plan-price fw-bold">
                <span translate="no">{plan.price}</span>
                <sup className="currency">FCFA</sup> / {plan.type}
              </p>
              <button
                className="btn-primary-top"
                onClick={() => handleRegisterClick(plan)}
              >
                Démarrez Essai Gratuit de 7 jours
              </button>
              <ul className="features">
                {plan.features.map((feature, featureIndex) => (
                  <li key={featureIndex}>
                    <span
                      className={`feature-icon ${
                        feature.hasFeature ? "text-success" : "text-danger"
                      }`}
                    >
                      {feature.hasFeature ? (
                        <FaCheckCircle />
                      ) : (
                        <FaCircleXmark />
                      )}
                    </span>
                    {feature.feature}
                  </li>
                ))}
              </ul>
              <button
                className="btn-primary"
                onClick={() => handleRegisterClick(plan)}
              >
                {t.getStarted}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Main;
