import React from "react";
import { Route, Routes } from "react-router-dom";
import Register from "./Register";
import OPTValidation from "./OPTValidation";
import Main from "./Main";
import { OTPProvider } from "./OTPContext";
import "./App.css";
import axios from "axios";
import SuccessPage from "./SuccessPage";

const App = () => {
  axios.defaults.baseURL = "https://api.nroo.one";
  // axios.defaults.baseURL = "http://localhost:8080";

  return (
    <div className="app-container">
      <OTPProvider>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/registration" element={<Register />} />
          <Route path="/otp-validation" element={<OPTValidation />} />
          <Route path="/success" element={<SuccessPage />} />
        </Routes>
      </OTPProvider>
    </div>
  );
};

export default App;
